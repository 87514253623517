import React from "react";
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";

function JoinDCleeQ() {
  return (
    <div>


<center>
<img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
      </center>
     
      <center className="JoinTheCleeQHeading">Join the <span className="JoinTheCleeQTag">CleeQ</span></center> {/* <center className="JoinTheCleeQHeading">Join the <span className="JoinTheCleeQTag">CleeQ<span className="JoinTheCleeQTag2">&#8482;</span></span></center> */}
      <center className="JoinTheCleeQSub">We are launching soon</center>
    </div>
  );
}

export default JoinDCleeQ;
