import React, { useEffect } from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import NavBar from "../../Components/NavBar/NavBar";
import JoinTheCleeQ from "./JoinTheCleeQ";
import Aos from "aos";
import "aos/dist/aos.css";

function CleeQ() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <div className="CleeQBody">
        {/* <header className="CleeQSticky">
          <NavBar />
        </header> */}

        <section>
          <div data-aos="fade-up" className="centered-container">
            <JoinTheCleeQ />
          </div>
        </section>
      </div>
    </div>
  );
}

export default CleeQ;
